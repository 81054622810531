import styled from "@emotion/styled"
import { typography, colors } from "../lib/presets.js"

export const P = styled(`p`)({
  fontFamily: `Queulat`,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  color: colors.text.medium,
  fontWeight: 300,
  lineHeight: 1.5
})
