const zIndex = {
  mapPinHover: 9996,
  loader: 9995,
  navigationBar: 9994,
  storeList: 991,
  backBar: 992,
  productsList: 993,
}

export default zIndex
