import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { padding, colors, typography } from "../../lib/presets"
import { IconButton } from "../../styles/buttons"

const ProductContainer = styled(`div`)(
  {
    display: `flex`,
    alignItems: `center`,
    borderTop: `1px solid #EEE`,
    padding: `${padding.small} 0`,
  }
)

const ProductName = styled(`h4`)(
  {
    padding: 0,
    margin: 0,
    paddingLeft: padding.xSmall,
    marginBottom: `0.15em`,
    fontSize: `1.2em`,
    color: colors.primary.blue,
    fontFamily: typography.header.family,
    textTransform: `uppercase`,
    letterSpacing: `1.0px`,
  }
)
const ProductPrice = styled(`h4`)(
  {
    padding: 0,
    margin: 0,
    paddingTop: `10px`,
    paddingLeft: `1.26em`,
    marginBottom: `0.15em`,
    fontSize: `1.0em`,
    color: colors.primary.blue,
    fontFamily: typography.header.family,
    textTransform: `uppercase`,
    letterSpacing: `1.0px`,
  }
)
const ProductSalePrice = styled(`h4`)(
  {
    padding: 0,
    margin: 0,
    paddingTop: `5px`,
    paddingLeft: `1.26em`,
    marginBottom: `0.15em`,
    fontSize: `1.0em`,
    color: colors.primary.blue,
    fontFamily: typography.header.family,
    textTransform: `uppercase`,
    letterSpacing: `1.0px`,
  }
)

const AvailableOnlineLink = ({product, country}) => {

  const Online_Store_Url = country === 'CA' ? product.Online_Store_Url_CA : product.Online_Store_Url;

  if (product.Category === 'Dips' || !Online_Store_Url) {
    return  <IconButton style={{ paddingLeft: padding.small, fontSize: `0.8em` }}>Only in Stores</IconButton>
  } else {

    return  <IconButton
                target="_blank"
                href={Online_Store_Url}
                style={{ paddingLeft: padding.small, fontSize: `0.8em` }}
                onClick={() => {
                  const message = JSON.stringify({
                    'event': 'ClickedOnBuyOnline',
                    'eventLabel': Online_Store_Url
                  });
                  window.parent.postMessage(message, '*');
                }}
              >
                <span>Also Available Online</span>
              </IconButton>

  }
}


const Product = ({ locatorType, product, property }) => {
  let price = product.price;
  let salePrice = 0;

  const Image_Url = property.address.country === 'CA' && product.Image_Url_CA ? product.Image_Url_CA : product.Image_Url;

  if (product.price === 0 && product.base_price !== 0) {
    price = product.base_price;
  }
  if (product.price !== 0 && product.base_price !== 0 && product.price < product.base_price) {
    price = product.base_price;
    salePrice = product.price;
  }

  return (
    <ProductContainer>
      <img src={Image_Url} style={{ height: `auto`, width: `100%`, maxWidth: `100px` }} />
      <div>
        <ProductName>{product.Product}</ProductName>

        {locatorType === 'stores' && <>
        {product.price !== 0 && <ProductPrice>Price: ${price}</ProductPrice>}
        {product.price !== 0 && salePrice !== 0 && <ProductSalePrice>Now on sale: ${salePrice}</ProductSalePrice>}
        </>}

        <AvailableOnlineLink product={product} country={property.address.country} />

      </div>
    </ProductContainer>
  )
}

export default Product
