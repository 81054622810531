import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import AutoAnchor from "../components/auto-anchor"
import { colors, typography } from "../lib/presets"

const bg = (color, hoverColor) => {
  return css`
    background: ${color};
    &:hover {
      background: ${hoverColor};
    }
  `
}

const d = css({
  cusor: `pointer`,
  display: `inline-block`,
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  padding: `1em 1.5em 0.9em`,
  textDecoration: `none`,
  borderRadius: `0.1875em`
})

const Button = ({ el = "button", big, color, isSelectable, ...props }) => {

  const Element = (props.href && AutoAnchor) || (props.to && Link) || el

  return (
    <Element
      css={[
        big && d,
        big && bg(colors.turquesa.three, colors.violeta.four),
        big && { color: `#FFFFFF` }
      ]}
      {...props}
    />
  )

}

Button.propTypes = {
  big: PropTypes.bool,
  isSelectable: PropTypes.bool,
}

export const IconButton = props => (
  <Button
    css={{
      backgroundColor: `transparent`,
      border: `none`,
      fontSize: `0.875em`,
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 500,
      fontFamily: typography.body.family,
      color: colors.primary.blue,
      marginRight: `1.0em`,
      [`i`]: {
        color: colors.text.light
      },
      ['span']: {
        cursor: `pointer`,
        marginLeft: `0.2em`
      },
      [`span:hover`]: {
        borderBottom: (props.isSelectable) ? `none` : `1.0px solid ${colors.turquesa.three}`,

      }
    }}
    {...props}
  />
)


export default Button
