const fonts = {
  body: {
    family: `'Arvo', 'serif'`
  },
  header: {
    family: `titular, sans-serif`,
  }
}

export default fonts
