import React, { Component, useRef, createRef } from 'react';

import { padding, colors, mq, zIndex, typography } from "../../lib/presets"
import styled from "@emotion/styled"
import { withLocator } from "../../providers/locator"
import SlideToggle from "react-slide-toggle";
import PropTypes from 'prop-types';
import { P } from "../../styles/p"
import Sticky from 'react-stickynode';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../../styles/react-accessible-accordion.css';


const ProductsContainer = styled(`div`)({
  position: `relative`,
  marginTop: `55px`,
  [mq.desktop]: {
    marginTop: 0
  }
})

const ToggleBarWrapper = styled.div({
  position: `relative`,
  zIndex: zIndex.productsList + 1
})

const ToggleBar = styled.button({
  position: `fixed`,
  width: `100%`,
  lineHeight: `50px`,
  minHeight: `50px`,
  padding: `0 ${padding.xSmall}`,
  boxShadow: `0 0 1.25em 0 rgba(0,0,0,0.07)`,
  backgroundColor: `#1E1A34`,
  letterSpacing: `1.25px`,
  textAlign: `center`,
  fontFamily: `SieteTitular2`,
  fontStyle: `normal`,
  fontSize: `20px`,
  color: '#e69f3b',
  boxSizing: `border-box`,
  border: `none`,
  cursor: `pointer`,
  [mq.desktop]: {
    maxWidth: `25rem`,
    position: `relative`,
  },
})

const FilterTitle = styled('div')({
  letterSpacing: `1.25px`,
  fontSize: `20px`,
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  padding: `30px 0 20px 30px`
})

const ClearFilterSpan = styled('a')({
  // position: `absolute`,
  // display: `block`,
  float: `right`,
  marginRight: `20px`,
  marginTop: `-45px`,
  padding: `5px 10px`,
  backgroundColor: `#00A3AD`,
  color: `#FFFFFF`,
  fontSize: `10px`,
  cursor: `pointer`
})

const ToggleBarIcon = styled('span')({
  color: `#F2A900`,
  ['span']: {
    verticalAlign: `inherit`,
    float: `right`,
    display: `block`,
    marginTop: `18px`,
    color: `#FFFFFF`
  }
})

const FilterApplied = styled('span')({
  float: `right`,
  marginRight: `20px`,
  padding: `5px 10px`,
  backgroundColor: `#1E1A34`,
  color: `#FFFFFF`,
  fontSize: `10px`
})

const Products__content = styled(`div`)({
  backgroundColor: `#F5F5F5`,
  width: `100%`,
  position: `absolute`,
  top: `50px`,
  zIndex: zIndex.productsList,
  boxSizing: `border-box`,
  [mq.desktop]: {
    maxWidth: `25em`,
    height: `calc(100vh - 105px)`,
  }
})

const ProductContainer = styled(`a`)({
  margin: `10px 15px`,
  padding: `15px 20px 5px`,
  height: `auto`,
  width: `40%`,
  borderRadius: `7px`,
  boxSizing: `border-box`,
  cursor: `pointer`,
  ['img']: {
    width: `100%`,
  },
  ['p']: {
    fontSize: `10px`,
    textAlign: `center`,
    textTransform: `uppercase`,
  },
},
props => ({
  background: props.isSelected ?  `#00A3AD` : `#eaeaea`,
  ['p']: {
    color: props.isSelected ?  `#FFFFFF` : `#707070`,
  }
}))
const DietaryContainer = styled(`a`)({
  margin: `10px 15px`,
  padding: `15px 30px 5px`,
  height: `auto`,
  width: `40%`,
  borderRadius: `7px`,
  boxSizing: `border-box`,
  cursor: `pointer`,
  ['img']: {
    width: `100%`,
  },
  ['p']: {
    fontSize: `10px`,
    textAlign: `center`,
    textTransform: `uppercase`,
  },
},
props => ({
  background: props.isSelected ?  `#00A3AD` : `#eaeaea`,
  ['p']: {
    color: props.isSelected ?  `#FFFFFF` : `#707070`,
  }
}))

const ProuductsGroup = styled(`p`)({
  fontFamily: `Queulat`,
  fontWeight: `bold`
})

const SwitchRestaurantsFilter = styled(`a`)({
  cursor: `pointer`,
},
props => ({
  borderBottom: props.isSelected ?  `3px solid #00A3AD` : `none`,
}))


class Group extends Component {

  render() {
    let numProducts = 0;
    this.props.selectedProducts.forEach((k, v) => {
      if (v.Category === this.props.group) numProducts += 1;
    });
    return (
      <ProuductsGroup>
        {this.props.group}
        {numProducts > 0 ? <FilterApplied>FILTER APPLIED</FilterApplied> : ''}
      </ProuductsGroup>
    )
  }
}

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false
    }
  }

  clearAll = () => {
    this.setState({selected: false});
  }

  handleState = () => {
    const { product, locator } = this.props;
    if (this.state.selected !== true) {
      this.setState({selected: true})
      this.props.updateSelectedProducts({product, action: 'add'})
      const message = JSON.stringify({
        'event': 'FilterByProduct',
        'eventLabel': product.Product
      });
      window.parent.postMessage(message, '*');
    } else {
      this.setState({selected: false});
      this.props.updateSelectedProducts({product, action: 'remove'})
    }
  }
  render() {
    const Image_Url = this.props.locator.country == 'CA' && this.props.product.Image_Url_CA ? this.props.product.Image_Url_CA : this.props.product.Image_Url;
    return (
       <ProductContainer tabIndex="0"
         onClick={() => { this.handleState() }}
         onKeyPress={() => { this.handleState() }}
         isSelected={this.state.selected}
       >
         <img src={Image_Url} />
         <P>{this.props.product.Product}</P>
       </ProductContainer>
    )
  }
}

class DietaryItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false
    }
  }

  clearAll = () => {
    this.setState({selected: false});
  }

  handleState = () => {
    const { product, locator } = this.props;
    if (this.state.selected !== true) {
      this.setState({selected: true})
      this.props.updateSelectedProducts({product, action: 'add'})
      const message = JSON.stringify({
        'event': 'FilterByDietary',
        'eventLabel': product.Name
      });
      window.parent.postMessage(message, '*');
    } else {
      this.setState({selected: false});
      this.props.updateSelectedProducts({product, action: 'remove'})
    }
  }
  render() {
    return (
       <DietaryContainer tabIndex="0"
         onClick={() => { this.handleState() }}
         onKeyPress={() => { this.handleState() }}
         isSelected={this.state.selected}
       >
         <img src={this.props.product.Image_Url} />
         <P>{this.props.product.Name}</P>
       </DietaryContainer>
    )
  }
}

class AccordionContainer extends Component {

  constructor(props) {
    super(props);

    this.props.products.all.forEach(p => {
      this[`${p.Product}_ref`] = React.createRef()
    });
    this.state = {
      productsGrouped: props.products.grouped,
      reset: false
    };
    this.props.products.dietary.forEach(d => {
      this[`${d.Name}_ref`] = React.createRef()
    });

  }

  clearProducts = (r) => {
    const { locator, products } = this.props;
    products.all.forEach(thing => {
      if (this[`${thing.Product}_ref`].current === null) return
      this[`${thing.Product}_ref`].current.clearAll();
    });
    products.dietary.forEach(thing => {
      if (this[`${thing.Name}_ref`].current === null) return
      this[`${thing.Name}_ref`].current.clearAll();
    });
    return this.props.clearAll()
  }

  render() {
    let items = [];
    let i = 0;
    {this.props.products.grouped.forEach((groupProducts, group) => {
      items.push(
        <AccordionItem key={i++}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Group
                key={i}
                group={group}
                selectedProducts={this.props.selectedProducts}
              />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div style={{display: `flex`, flexWrap: `wrap`}}>
              {groupProducts.map((p, i) => <Product
                updateSelectedProducts={this.props.updateSelectedProducts}
                ref={this[`${p.Product}_ref`]}
                locator={this.props.locator}
                product={p}
                key={i} />
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      )
    })}

    let dietaryItems = [];
    let n = 0;
    {this.props.products.dietary.forEach((dietaryItem) => {
      dietaryItems.push(
      <DietaryItem
      updateSelectedProducts={this.props.updateSelectedProducts}
      ref={this[`${dietaryItem.Name}_ref`]}
      locator={this.props.locator}
      product={dietaryItem}
      key={n++} />
      )
    })}

    return <>{this.props.locator.locatorType === 'stores' ?
    <AccordionStores _this={this} locator={this.props.locator} items={items} selectedProducts={this.props.selectedProducts}></AccordionStores> :
    <AccordionRestaurants _this={this} locator={this.props.locator} items={items} dietaryItems={dietaryItems} selectedProducts={this.props.selectedProducts}></AccordionRestaurants>
    }</>

  }
}

const AccordionStores = (props) => {
  return <>
  <FilterTitle><p>Filter By Product</p>
    {props.selectedProducts.size !== 0 ? <ClearFilterSpan tabIndex="0" onClick={() => { props._this.clearProducts() }} onKeyPress={() => { props._this.clearProducts() }}>CLEAR ALL</ClearFilterSpan> : null}
  </FilterTitle>
  <Accordion allowZeroExpanded={true} style={{height: `calc(100% - 70px)`, overflow: `auto`}}>
    {props.items}
  </Accordion>
  </>
}

const filterRestaurantsBy = (props, by, selected) => {
  if (by === selected) return
  props._this.clearProducts()
  props.locator.setFilterRestaurantsBy(by)
}


const AccordionRestaurants = (props) => {
  const filterBy = props.locator.filterRestaurantsBy
  return <>
  <FilterTitle>
    <SwitchRestaurantsFilter tabIndex="0" isSelected={filterBy === 'products' ? true : false} onKeyPress={() => filterRestaurantsBy(props, 'products', filterBy)} onClick={() => filterRestaurantsBy(props, 'products', filterBy)}>Product</SwitchRestaurantsFilter> | <SwitchRestaurantsFilter tabIndex="0" isSelected={filterBy === 'dietary' ? true : false} onKeyPress={() => filterRestaurantsBy(props, 'dietary', filterBy)} onClick={() => filterRestaurantsBy(props, 'dietary', filterBy)}>Dietary preferences</SwitchRestaurantsFilter>
    {props.selectedProducts.size !== 0 ? <ClearFilterSpan tabIndex="0" onClick={() => { props._this.clearProducts() }} onKeyPress={() => { props._this.clearProducts() }}>CLEAR ALL</ClearFilterSpan> : null}
  </FilterTitle>
  {filterBy === 'products' ?
  <Accordion allowZeroExpanded={true} style={{height: `calc(100% - 70px)`, overflow: `auto`}}>{props.items}</Accordion>
  :
  <div style={{height: `calc(100% - 70px)`, overflow: `auto`}}>
    <div style={{display: 'flex', flexWrap: 'wrap', marginLeft: 20}}>
      {props.dietaryItems}
    </div>
  </div>
  }
  </>
}


const ToggleBarContent = (props) => {
  const propertyType = props.locator.locatorType === 'stores' ? 'Filter stores by Product' : 'Filter eateries by'
  if (props.isMoving === false && props.toggleState !== 'EXPANDED') {
    return <ToggleBarIcon>{(props.selectedProducts.size == 0) ? propertyType : 'Filter Applied'}
      <span className="fas fa-chevron-right align-middle"></span>
    </ToggleBarIcon>
  } else {
    return <ToggleBarIcon>Results Found ({props.locator.properties.length})<span className="fas fa-chevron-down align-middle"></span></ToggleBarIcon>;
  }
}

class SearchByProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: new Map(),
      toggleState: null,
      toggleEvent: Date.now()
    };
  }

  collapseProductsList = () => {
    if (this.state.toggleState !== 'expanded') return;
    this.setState({ toggleEvent: Date.now() });
  };

  clearAll () {
    const updateState = async () => {
      await this.setState({selectedProducts: new Map()})
    }
    updateState()
    .then(() => this.props.locator.filterByProducts(this.state.selectedProducts, this.props.locator))
  }

  selectedProducts (data) {
    const updateMap = (existingMap, data) => {
      const newMap = new Map(existingMap)
      if (data.action === 'remove') {
        newMap.delete(data.product)
      } else if (data.action === 'add') {
        newMap.set(data.product, true)
      } else if (data.action === 'clear') {
        newMap.clear();
      }
      return newMap
    }
    const updateState = async () => {
      await this.setState({
        selectedProducts: updateMap(this.state.selectedProducts, data)
      })
    }
    updateState()
    .then(() => this.props.locator.filterByProducts(this.state.selectedProducts, this.props.locator))
  }

  render() {
    const {locator, products} = this.props

    return (
      <>
      <div id={'onMapPinClick'} style={{display: `none`}} onClick={() => this.collapseProductsList()}></div>
      <SlideToggle
        collapsed={true}
        onExpanded={() => {
          locator.hideProperties(true)
          this.setState({toggleState: 'expanded'})
        }}
        onCollapsed={() => {
          locator.hideProperties(false)
          this.setState({toggleState: 'collapsed'})
        }}
        toggleEvent={this.state.toggleEvent}
        >
        {({ toggle, setCollapsibleElement, toggleState, event, expandEvent, isMoving }) => (
          <ProductsContainer>
            <ToggleBarWrapper>
              <ToggleBar className="products__toggle" onClick={toggle}>
                <ToggleBarContent
                  selectedProducts={this.state.selectedProducts}
                  toggleEvent={event}
                  toggleState={toggleState}
                  isMoving={isMoving}
                  locator={locator}
                />
              </ToggleBar>
            </ToggleBarWrapper>
            <Products__content ref={setCollapsibleElement}>
              <AccordionContainer
                clearAll={ this.clearAll.bind(this) }
                updateSelectedProducts={ this.selectedProducts.bind(this) }
                selectedProducts={ this.state.selectedProducts }
                locator={ locator }
                products={ products }
              />
            </Products__content>
          </ProductsContainer>
        )}
      </SlideToggle>
      </>
    )
  }
}

//export default withLocator(SearchByProduct)
export default SearchByProduct
