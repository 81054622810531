const colors = require(`./colors`).default
const zIndex = require(`./zindex`).default
const typography = require(`./typography`).default

const mq = {
    mobile: `@media (min-width: 400px)`,
    phablet: `@media (min-width: 550px)`,
    tablet: `@media (min-width: 750px)`,
    desktop: `@media (min-width: 1000px)`,
    xl: `@media (min-width: 1200px)`,
    xxl: `@media (min-width: 1600px)`,
}

module.exports = {
  zIndex: zIndex,
  colors: colors,
  typography: typography,
  mq: mq,
  padding: {
    default: `2.5em`,
    small: `1.25em`,
    xSmall: `1.0em`,
    xxSmall: `0.75em`
  }
}
