import React from "react"
import { colors, typography, padding } from "../../lib/presets"
import styled from "@emotion/styled"

const ProductHeader = styled(`div`)(
  {
    padding: `${padding.small} 0`,
    fontSize: `0.9em`,
    fontWeight: 500,
    fontFamily: typography.body.family,
    color: colors.text.medium,
    [`i`]: {
      color: colors.text.light
    }
  }
)


export default ({ count, hasPhoneNumber }) => (
  <ProductHeader>
    {(count !== 0) && <i className="far fa-taco"></i>}
    {(count !== 0) ? ` This store carries` : hasPhoneNumber === true ? ` Call ahead to see which products are in stock.` : ''}
    {(count === 1) && ` ${count} product`}
    {(count > 1) && ` ${count} products`}
  </ProductHeader>
)
