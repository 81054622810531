import React from "react"
import PropTypes from "prop-types"
import { padding, colors, mq, typography } from "../../lib/presets"
import styled from "@emotion/styled"
import { IconButton } from "../../styles/buttons"
import { withLocator } from "../../providers/locator"

const LinksContainer = styled.div({
  [`a`]: {
    marginRight: padding.small
  }
})

const StoreLinks = ({ locator, store, productsSorted, hasPhoneNumber, hideProductsLink = false, isSelectable, ...props }) => {

  const PhoneNumberHref = (isSelectable == false) ? 'tel:' +  store.phoneNumber : null;

  return (
    <LinksContainer {...props}>
      {hasPhoneNumber && (
        <IconButton
          onClick={() => {
            if (isSelectable == true) return;
            const message = JSON.stringify({
              'event': 'ClickedOnStorePhoneNumber',
              'eventLabel': store.phoneNumber
            });
            window.parent.postMessage(message, '*');
          }}
          href={PhoneNumberHref} isSelectable={isSelectable}><i className="far fa-phone"></i>
          <span>{store.phoneNumber}</span>
        </IconButton>
      )}
      {(store.products != undefined && hideProductsLink == false) && (
        <IconButton onClick={() => { locator.selectProperty(store) }}>
          {productsSorted.length !== 0 && <i className="far fa-taco" style={{marginRight: `4px`}}></i>}
            {productsSorted.length > 1 && (`${productsSorted.length} Products`)}
            {productsSorted.length === 1 && (`${productsSorted.length} Product`)}
        </IconButton>
      )}
    </LinksContainer>
  )
}

export default withLocator(StoreLinks)
