import React, { useEffect, useState } from "react"
import { padding } from "../../lib/presets"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { withLocator } from "../../providers/locator"
import "../../styles/algolia-places.css"
import $ from "jquery";


const SearchBar = ({ locator }) => {

  const [isLocationLoaded, setLocationLoaded] = useState(false);
  const [isGmapsApiLoaded, setGmapsApiLoaded] = useState(false);

  useEffect(() => {

    var loadGmapsApi = setInterval(() => {
      if (window.google) {
        setGmapsApiLoaded(true);
        clearInterval(loadGmapsApi);
      }
    }, 1000);

    locator.loading(true)
    setLocationLoaded(false)

    navigator.geolocation.getCurrentPosition((response) => {

      const coords = response.coords;
      const lat = coords.latitude;
      const lng = coords.longitude;

      locator.findStores({ latlng: { lat, lng }, fromGeoLoc: true })

      locator.loading(false)
      setLocationLoaded(true)

    }, function (error) {

      if (error.code == error.PERMISSION_DENIED) {
        locator.setLocationDenied(true)
        locator.loading(false)
      }

    })

  }, []);


  return (
    <div style={{ flexGrow: 1, background: "#FFFFFF" , fontFamily: `'Queulat', 'serif'` }} id="reverse-city">
      {isGmapsApiLoaded &&
        <>
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            componentRestrictions: {
              country: ['us', 'ca', 'mx'],
            }
          }}
          selectProps={{
            placeholder: 'Please enter your address, city, or zip code...',
            onChange: function(suggestion) {
              let country = 'US';
              geocodeByPlaceId(suggestion.value.place_id)
              .then(results => {

                if (results[0].address_components) {
                  results[0].address_components.forEach(component => {
                    if (component.types[0] == 'country') {
                      country = component['short_name'];
                    }
                  })
                }

                locator.setCountry(country)
                return getLatLng(results[0])
              })
              .then(({ lat, lng }) => {
                const latlng = {
                  lat: lat,
                  lng: lng
                }
                locator.findStores({ latlng: latlng})
                $('.PropertyListContainer').stop().animate({scrollTop: 0}, 100);
                const message = JSON.stringify({
                  'event': 'SearchLocation',
                  'eventLabel': suggestion.label
                });
                window.parent.postMessage(message, '*');
              })
              .catch(error => console.error(error));
            },

          }}
        />
      </>}
    </div>
  )
}

//export default withLocator(SearchBar)
export default SearchBar
