import DefaultPin from '../images/defaultPin.png'
import DefaultPinHover from '../images/defaultPinHover.png'
import DefaultRstaurantPin from '../images/defaultRestaurantPin.png'
import DefaultRstaurantPinHover from '../images/defaultRestaurantPinHover.png'

import AlbertsonsPin from '../images/mapPins/Albertsons/Albertsons_pin.png'
import AlbertsonsPinHover from '../images/mapPins/Albertsons/Albertsons_pin_hover.png'
import BuceesPin from '../images/mapPins/Bucees/Bucees_pin.png'
import BuceesPinHover from '../images/mapPins/Bucees/Bucees_pin_hover.png'
//Central Market
import CityMarketPin from '../images/mapPins/City Market/CityMarket_pin.png'
import CityMarketPinHover from '../images/mapPins/City Market/CityMarket_pin_hover.png'
import CostcoPin from '../images/mapPins/Costco/Costco_pin.png'
import CostcoPinHover from '../images/mapPins/Costco/Costco_pin_hover.png'
import CVSPin from '../images/mapPins/CVS/CVS_pin.png'
import CVSPinHover from '../images/mapPins/CVS/CVS_pin_hover.png'
import DillonsPin from '../images/mapPins/Dillons/Dillons_pin.png'
import DillonsPinHover from '../images/mapPins/Dillons/Dillons_pin_hover.png'
import ErewhonPin from '../images/mapPins/Erewhon/Erewhon_pin.png'
import ErewhonPinHover from '../images/mapPins/Erewhon/Erewhon_pin_hover.png'
import Fred_MeyerPin from '../images/mapPins/Fred Meyer/Fred_Meyer_pin.png'
import Fred_MeyerPinHover from '../images/mapPins/Fred Meyer/Fred_Meyer_pin_hover.png'
import FrysPin from '../images/mapPins/Frys/Frys_pin.png'
import FrysPinHover from '../images/mapPins/Frys/Frys_pin_hover.png'
import HarrisTeeterPin from '../images/mapPins/Harris Teeter/HarrisTeeter_pin.png'
import HarrisTeeterPinHover from '../images/mapPins/Harris Teeter/HarrisTeeter_pin_hover.png'
import HEBPin from '../images/mapPins/HEB/HEB_pin.png'
import HEBPinHover from '../images/mapPins/HEB/HEB_pin_hover.png'
import King_SooperPin from '../images/mapPins/King Sooper/King_Sooper_pin.png'
import King_SooperPinHover from '../images/mapPins/King Sooper/King_Sooper_pin_hover.png'
import KrogerPin from '../images/mapPins/Kroger/Kroger_pin.png'
import KrogerPinHover from '../images/mapPins/Kroger/Kroger_pin_hover.png'
import MarianosPin from '../images/mapPins/Marianos/Marianos_pin.png'
import MarianosPinHover from '../images/mapPins/Marianos/Marianos_pin_hover.png'
import PublixPin from '../images/mapPins/Publix/Publix_pin.png'
import PublixPinHover from '../images/mapPins/Publix/Publix_pin_hover.png'
import RalphsPin from '../images/mapPins/Ralphs/Ralphs_pin.png'
import RalphsPinHover from '../images/mapPins/Ralphs/Ralphs_pin_hover.png'
import SafewayPin from '../images/mapPins/Safeway/Safeway_pin.png'
import SafewayPinHover from '../images/mapPins/Safeway/Safeway_pin_hover.png'
import SamsClubPin from '../images/mapPins/Sam_s Club/Sams_pin.png'
import SamsClubPinHover from '../images/mapPins/Sam_s Club/Sams_pin_hover.png'
import SmithsPin from '../images/mapPins/Smiths/Smiths_pin.png'
import SmithsPinHover from '../images/mapPins/Smiths/Smiths_pin_hover.png'
import SproutsPin from '../images/mapPins/Sprouts/Sprouts_pin.png'
import SproutsPinHover from '../images/mapPins/Sprouts/Sprouts_pin_hover.png'
import TargetPin from '../images/mapPins/Target/Target_pin.png'
import TargetPinHover from '../images/mapPins/Target/Target_pin_hover.png'
import WalmartPin from '../images/mapPins/Walmart/Walmart_pin.png'
import WalmartPinHover from '../images/mapPins/Walmart/Walmart_pin_hover.png'
import WegmansPin from '../images/mapPins/Wegmans/Wegmans_pin.png'
import WegmansPinHover from '../images/mapPins/Wegmans/Wegmans_pin_hover.png'
import WFMPin from '../images/mapPins/WFM/WFM_pin.png'
import WFMPinHover from '../images/mapPins/WFM/WFM_pin_hover.png'
import VonsPin from '../images/mapPins/Vons/Vons_pin.png'
import VonsPinHover from '../images/mapPins/Vons/Vons_pin_hover.png'
import RandallsPin from '../images/mapPins/Randalls/Randalls_pin.png'
import RandallsPinHover from '../images/mapPins/Randalls/Randalls_pin_hover.png'
import RaleysPin from '../images/mapPins/Raleys/Raleys_pin.png'
import RaleysPinHover from '../images/mapPins/Raleys/Raleys_pin_hover.png'
import TomThumbPin from '../images/mapPins/Tom Thumb/Tom_Thumb_pin.png'
import TomThumbPinHover from '../images/mapPins/Tom Thumb/Tom_Thumb_pin_hover.png'
import NaturalGrocersPin from '../images/mapPins/Natural Grocers/Natural_Grocers_pin.png'
import NaturalGrocersPinHover from '../images/mapPins/Natural Grocers/Natural_Grocers_pin_hover.png'
import FairwayMarketsPin from '../images/mapPins/Fairway Markets/Fairway_Markets_pin.png'
import FairwayMarketsPinHover from '../images/mapPins/Fairway Markets/Fairway_Markets_pin_hover.png'
import WheatsvilleCoOpPin from '../images/mapPins/WheatsvilleCoOp/WheatsvilleCoOp_pin.png'
import WheatsvilleCoOpPinHover from '../images/mapPins/WheatsvilleCoOp/WheatsvilleCoOp_pin_hover.png'
import RoyalBlueGroceryPin from '../images/mapPins/Royal Blue Grocery/Royal_Blue_Grocery_pin.png'
import RoyalBlueGroceryPinHover from '../images/mapPins/Royal Blue Grocery/Royal_Blue_Grocery_pin_hover.png'
import MetroIncPin from '../images/mapPins/Metro Inc/MetroInc_pin.png'
import MetroIncPinHover from '../images/mapPins/Metro Inc/MetroInc_pin_hover.png'
import PavillionsPin from '../images/mapPins/Pavillions/Pavillions_pin.png'
import PavillionsPinHover from '../images/mapPins/Pavillions/Pavillions_pin_hover.png'

// Restauranrs
import SweetgreenPin from '../images/restaurantsMapPins/Sweetgreen/Sweetgreen_pin.png'
import SweetgreenPinHover from '../images/restaurantsMapPins/Sweetgreen/Sweetgreen_pin_hover.png'
import JuicelandPin from '../images/restaurantsMapPins/Juiceland/Juiceland_pin.png'
import JuicelandPinHover from '../images/restaurantsMapPins/Juiceland/Juiceland_pin_hover.png'
import CoffeeBeanPin from '../images/restaurantsMapPins/CoffeeBean/CoffeeBean_pin.png'
import CoffeeBeanPinHover from '../images/restaurantsMapPins/CoffeeBean/CoffeeBean_pin_hover.png'
import TrueFoodPin from '../images/restaurantsMapPins/TrueFood/TrueFood_pin.png'
import TrueFoodPinHover from '../images/restaurantsMapPins/TrueFood/TrueFood_pin_hover.png'
import EverytablePin from '../images/restaurantsMapPins/Everytable/Everytable_pin.png'
import EverytablePinHover from '../images/restaurantsMapPins/Everytable/Everytable_pin_hover.png'



export const getPin = (retailer) => {
  let pin;
  switch(retailer) {
    case 'Albertsons': pin = AlbertsonsPin
    break;
    case 'Buc-ees': pin = BuceesPin
    break;
    case 'City Market': pin = CityMarketPin
    break;
    case 'Costco': pin = CostcoPin
    break;
    case 'CVS': pin = CVSPin
    break;
    case 'Dillons': pin = DillonsPin
    break;
    case 'Erewhon': pin = ErewhonPin
    break;
    case 'Fred Meyer': pin = Fred_MeyerPin
    break;
    case 'Fry’s': pin = FrysPin
    break;
    case 'Harris Teeter': pin = HarrisTeeterPin
    break;
    case 'H-E-B': pin = HEBPin
    break;
    case 'King Soopers': pin = King_SooperPin
    break;
    case 'Kroger': pin = KrogerPin
    break;
    case 'Mariano’s': pin = MarianosPin
    break;
    case 'Publix': pin = PublixPin
    break;
    case 'Ralphs': pin = RalphsPin
    break;
    case 'Safeway': pin = SafewayPin
    break;
    case 'Sam\'s Club': pin = SamsClubPin
    break;
    case 'Smith’s': pin = SmithsPin
    break;
    case 'Sprouts': pin = SproutsPin
    break;
    case 'Target': pin = TargetPin
    break;
    case 'Walmart': pin = WalmartPin
    break;
    case 'Wegmans': pin = WegmansPin
    break;
    case 'Whole Foods': pin = WFMPin
    break;
    case 'Vons': pin = VonsPin
    break;
    case 'Randalls': pin = RandallsPin
    break;
    case 'Raley\'s': pin = RaleysPin
    break;
    case 'Tom Thumb': pin = TomThumbPin
    break;
    case 'Natural Grocers': pin = NaturalGrocersPin
    break;
    case 'Fairway Markets': pin = FairwayMarketsPin
    break;
    case 'Wheatsville Co-Op': pin = WheatsvilleCoOpPin
    break;
    case 'Royal Blue Grocery': pin = RoyalBlueGroceryPin
    break;
    case 'Metro Inc': pin = MetroIncPin
    break;
    case 'Pavillions': pin = PavillionsPin
    break;
    default: pin = DefaultPin
  }
  return pin;
}

export const getPinHover = (retailer) => {
  let pin;
  switch(retailer) {
    case 'Albertsons': pin = AlbertsonsPinHover
    break;
    case 'Buc-ees': pin = BuceesPinHover
    break;
    case 'City Market': pin = CityMarketPinHover
    break;
    case 'Costco': pin = CostcoPinHover
    break;
    case 'CVS': pin = CVSPinHover
    break;
    case 'Dillons': pin = DillonsPinHover
    break;
    case 'Erewhon': pin = ErewhonPinHover
    break;
    case 'Fred Meyer': pin = Fred_MeyerPinHover
    break;
    case 'Fry’s': pin = FrysPinHover
    break;
    case 'Harris Teeter': pin = HarrisTeeterPinHover
    break;
    case 'H-E-B': pin = HEBPinHover
    break;
    case 'King Soopers': pin = King_SooperPinHover
    break;
    case 'Kroger': pin = KrogerPinHover
    break;
    case 'Mariano’s': pin = MarianosPinHover
    break;
    case 'Publix': pin = PublixPinHover
    break;
    case 'Ralphs': pin = RalphsPinHover
    break;
    case 'Safeway': pin = SafewayPinHover
    break;
    case 'Sam\'s Club': pin = SamsClubPinHover
    break;
    case 'Smith’s': pin = SmithsPinHover
    break;
    case 'Sprouts': pin = SproutsPinHover
    break;
    case 'Target': pin = TargetPinHover
    break;
    case 'Walmart': pin = WalmartPinHover
    break;
    case 'Wegmans': pin = WegmansPinHover
    break;
    case 'Whole Foods': pin = WFMPinHover
    break;
    case 'Vons': pin = VonsPinHover
    break;
    case 'Randalls': pin = RandallsPinHover
    break;
    case 'Raley\'s': pin = RaleysPinHover
    break;
    case 'Tom Thumb': pin = TomThumbPinHover
    break;
    case 'Natural Grocers': pin = NaturalGrocersPinHover
    break;
    case 'Fairway Markets': pin = FairwayMarketsPinHover
    break;
    case 'Wheatsville Co-Op': pin = WheatsvilleCoOpPinHover
    break;
    case 'Royal Blue Grocery': pin = RoyalBlueGroceryPinHover
    break;
    case 'Metro Inc': pin = MetroIncPinHover
    break;
    case 'Pavillions': pin = PavillionsPinHover
    break;
    default: pin = DefaultPinHover
  }
  return pin;
}

export const getRestaurantPin = (retailer) => {
  let pin;
  if (retailer.startsWith('Juiceland')) retailer = 'Juiceland';
  switch(retailer) {
    case 'Sweetgreen': pin = SweetgreenPin
    break;
    case 'Juiceland': pin = JuicelandPin
    break;
    case 'The Coffee Bean and Tea Leaf': pin = CoffeeBeanPin
    break;
    case 'True Food Kitchen': pin = TrueFoodPin
    break;
    case 'Everytable': pin = EverytablePin
    break;
    default: pin = DefaultRstaurantPin
  }
  return pin;
}

export const getRestaurantPinHover = (retailer) => {
  let pin;
  if (retailer.startsWith('Juiceland')) retailer = 'Juiceland';
  switch(retailer) {
    case 'Sweetgreen': pin = SweetgreenPinHover
    break;
    case 'Juiceland': pin = JuicelandPinHover
    break;
    case 'The Coffee Bean and Tea Leaf': pin = CoffeeBeanPinHover
    break;
    case 'True Food Kitchen': pin = TrueFoodPinHover
    break;
    case 'Everytable': pin = EverytablePinHover
    break;
    default: pin = DefaultRstaurantPinHover
  }
  return pin;
}


