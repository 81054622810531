const colors = {
  background: {
    gray: `#F5F5F5`
  },
  text: {
    dark: `#1E1A34`,
    medium: `#575268`,
    light: `#888594`
  },
  primary: {
    blue: "#1E1A34",
    yellow: "#F2A900"
  },
  fuego: {
    default: "#9A3324",
    two: "#CF4520",
    three: "#ED8B00",
    four: "#FFC72C",
    five: "#F3DD6D"
  },
  violeta: {
    one: "#830065",
    two: "#C6007E",
    three: "#C724B1",
    four: "#F04E98",
    five: "#F99FC9"
  },
  morado: {
    one: "#2E1A47",
    two: "#582C83",
    three: "#8A69D4",
    four: "#9063CD",
    five: "#D7C6E6"
  },
  turquesa: {
    one: "#002B49",
    two: "#004F71",
    three: "#00A3AD",
    four: "#40C1AC",
    five: "#88DBDF"
  },
  verde: {
    one: "#154734",
    two: "#007A33",
    three: "#78BE20",
    four: "#64A70B",
    five: "#BCE194"
  }
}

export default colors
