import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useInternalStatus } from "../hooks/useInternalPaths"

const normalizeHref = input => {
  let href = input

  href = href.replace("http://", "https://")

  if (href.startsWith("https://app.sparklestories.com/")) {
    href = href.slice(30)
  }

  if (!href.includes("?") && !href.includes("#") && !href.endsWith("/") && !href.includes("tel")) {
    href = href + "/"
  }

  return href
}

const useLink = href => {
  const url = normalizeHref(href)
  const isInternal = useInternalStatus(url)

  return {
    url,
    mode: isInternal ? "internal" : "external",
  }
}

const AutoAnchor = ({ href, target, ...anchorProps }) => {
  const { url, mode } = useLink(href)

  switch (mode) {
    case "internal":
      return <Link to={url} {...anchorProps} />

    case "external":
      if (target && target === "_blank") {
        anchorProps.rel = "noopener noreferrer"
      }

      return <a href={url} target={target} {...anchorProps} />
  }
}

AutoAnchor.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  track: PropTypes.func,
  target: PropTypes.string,
}

export default AutoAnchor
