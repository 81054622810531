import React, { useEffect, useState }  from 'react'
import GoogleMapReact from 'google-map-react'
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { padding, colors, mq, zIndex } from "../../lib/presets"
import { withLocator } from "../../providers/locator"
import { getPin, getPinHover, getRestaurantPin, getRestaurantPinHover } from "../../lib/mapPin"
import $ from "jquery";


const mapDefaults = {
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  center: {
    lat: 30.2672,
    lng: -97.7431,
  },
  zoom: 14,
}

const MapContainer = styled(`div`)(
  {
    minHeight: `250px`,
    height: `250px`,
    marginTop: `50px`,
    [mq.desktop]: {
      flexGrow: 1,
      height: `calc(100vh - 55px)`,
      width: `100%`,
      marginTop: `-50px`,
      zIndex: 5000,
    }
  }
)
/*
const restautantPinStyle = {
  position: `relative`,
  zIndex: 1,
  //transition: `all .2s ease-in-out`,
  width: `4.8em`,
  height: `5.0em`,
  margin: `-3em 0 0 -3em`,
  [`:hover`]: {
    transform: `scale(1.1)`,
  }
}
*/
const restautantPinStyle = {
  position: `relative`,
  zIndex: 1,
  //transition: `all .2s ease-in-out`,
  width: `6.0em`,
  height: `6.22em`,
  margin: `-3em 0 0 -3em`,
  [`:hover`]: {
    transform: `scale(1.1)`,
  }
}


const storePinStyle = {
  position: `relative`,
  zIndex: 1,
  //transition: `all .2s ease-in-out`,
  width: `6.0em`,
  height: `6.22em`,
  margin: `-3em 0 0 -3em`,
  [`:hover`]: {
    transform: `scale(1.1)`,
  }
}

const mouseOver = (e, props) => {
  e.target.style.zIndex = zIndex.mapPinHover;
  if (props.locator.locatorType === 'restaurants') {
    e.target.src = getRestaurantPinHover(props.retailer);
  } else {
    e.target.src = getPinHover(props.retailer);
  }
  const position = $('.property_' + props.objectId).position();
  const offset = $('.property_' + props.objectId).parent().offset();
  if (!position || !offset) return ;
  $('.property_' + props.objectId).addClass('activeStore');
  $('.PropertyListContainer').stop().animate({scrollTop: position.top - (offset.top - 105)}, 200);
}

const mouseOut = (e, props) => {
  e.target.style.zIndex = 1;
  if (props.locator.locatorType === 'restaurants') {
    e.target.src = getRestaurantPin(props.retailer);
  } else {
    e.target.src = getPin(props.retailer);
  }
  $('.property_' + props.objectId).removeClass('activeStore');
}

const onPinClick = (props) => {
  $('#onMapPinClick').trigger('click')
  if (!props.locator) return;
  props.locator.selectProperty(props.property)
  $('.PropertyListContainer').scrollTop(0)
  const event = props.locatorType === 'stores' ? 'ClickOnStoreMarker' : 'ClickOnRestaurantMarker';
  const message = JSON.stringify({
    'event': event,
    'eventLabel': props.property.name
  });
  window.parent.postMessage(message, '*');
}

const Pin = ({ ...props }) => {
  const pinSrc = props.locatorType === 'restaurants' ? getRestaurantPin(props.retailer) : getPin(props.retailer);
  const pinStyle = props.locatorType === 'restaurants' ? restautantPinStyle : storePinStyle;
  return props.locator == null ?
  <div onClick={(e) => onPinClick(props)}>
    <img src={pinSrc} style={pinStyle} />
  </div>
   :
  <div onClick={() => onPinClick(props)}>
    <img
      className={`pin_${props.objectId}`}
      onMouseEnter={(e) => mouseOver(e, props)}
      onMouseLeave={(e) => mouseOut(e, props)}
      src={pinSrc}
      style={pinStyle}
    />
  </div>
}

const ContextMap = ({ locator }) => {
  const center = locator.latlng;
  const clonedCenter = Object.assign({}, center);
  const propertiesCopy = Object.assign([], locator.properties);
  const sorteStoresdByLat = propertiesCopy.sort((a, b) => (a._geoloc.lat > b._geoloc.lat) ? -1 : 1);
  if ( locator.selected != null ) {
    clonedCenter.lat = parseFloat(locator.selected._geoloc.lat);
    clonedCenter.lng = parseFloat(locator.selected._geoloc.lon);
  }

  const [isGmapsApiLoaded, setGmapsApiLoaded] = useState(false);
  useEffect(() => {
    var loadGmapsApi = setInterval(() => {
      if (window.google) {
        setGmapsApiLoaded(true);
        clearInterval(loadGmapsApi);
      }
    }, 1000);
  });

  return isGmapsApiLoaded ? (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapDefaults.apiKey }}
        defaultCenter={locator.geoLocLatLng || mapDefaults.center}
        defaultZoom={mapDefaults.zoom}
        zoom={locator.selected != null ? 15 : mapDefaults.zoom}
        center={locator.selected != null ? clonedCenter : center}
        options={{
          zoomControlOptions: { position: 7 },
          fullscreenControl:false,
        }}
        >
        {(sorteStoresdByLat && locator.selected === null) && (
          sorteStoresdByLat.map((property, index) => {
            return <Pin style={{ zIndex: index }} objectId={property.objectID} key={index} retailer={property.name} lat={property._geoloc.lat} lng={property._geoloc.lon} locator={locator} property={property} locatorType={locator.locatorType} />
          })
        )}
        {locator.selected !== null && (<Pin lat={locator.selected._geoloc.lat} lng={locator.selected._geoloc.lon} retailer={locator.selected.name} locatorType={locator.locatorType} />)}
      </GoogleMapReact>
    </MapContainer>
  ) : <></>
}

export const MapWithLocator = withLocator(ContextMap)


// const ContentfulStoreMap = ({ contentfulStore }) => (
//   <MapContainer>
//     <GoogleMapReact bootstrapURLKeys={{ key: mapDefaults.apiKey }} defaultCenter={{ lat: contentfulStore.location.lat, lng: contentfulStore.location.lon }} defaultZoom={15}>
//       <Pin lat={contentfulStore.location.lat} lng={contentfulStore.location.lon} />
//     </GoogleMapReact>
//   </MapContainer>
// )




// const Map = ({ contentfulStore }) => {
//   return (
//     contentfulStore ? <ContentfulStoreMap contentfulStore={contentfulStore} /> : MapWithLocator
//   )
// }

// export default Map
