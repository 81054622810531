import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { colors, padding, typography } from "../../lib/presets"
import styled from "@emotion/styled"

import MagnifyingGlass from "../../images/magnifying-glass.svg";
import HomeBtn from "../../images/homeBtn.png";

const magnifyingGlasstyle = css({
  display: `flex`,
  flexDirection: `column`,
  flexShrink: 0,
  alignItems: `center`,
  backgroundColor: `#F4A834`,
  padding: `15px`,
  height: `100%`,
  width: `auto`,
  fill: `#FFFFFF`,
  boxSizing: `border-box`,
})

const HomeButton = styled(`div`)({
  backgroundColor: '#F4A834',
  padding: `7px`,
  [`img`]: {
    height: `37px`
  },
  [`:hover`]: {
    backgroundColor: '#1E1A34',
  }
})

const Logo = ({ data }) => {

  const { locationNavigationBar } = data

  let { logo, logoLink } = locationNavigationBar

  return (
    <>
    <HomeButton><a href={'/'}><img src={HomeBtn} /></a></HomeButton>
    <MagnifyingGlass css={magnifyingGlasstyle} > TTT</MagnifyingGlass>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query Logo {
        locationNavigationBar: contentfulLocationNavigationBar {
          logoLink
          logo {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Logo data={data} {...props} />}
  />
)
