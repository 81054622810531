import React from "react"
import PropTypes from "prop-types"
import { padding, colors } from "../../lib/presets"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const ResultsBarContainer = styled.div({
  width: `calc(100% - ${padding.xSmall * 2.0} - ${padding.default})`,
  lineHeight: `2.0em`,
  padding: `0 ${padding.xSmall}`,
  border: `none`,
  borderRadius: `3px`,
  //boxShadow: `0 0 1.25em 0 rgba(0,0,0,0.07)`,
  backgroundColor: colors.background.gray,
  fontFamily: `titular, sans-serif`,
  fontStyle: `normal`,
  fontWeight: 700,
  fontSize: `1.375em`,
  color: colors.primary.blue,
  letterSpacing: `1.0px`,
},
props => ({
  textAlign: props.count === 0 ? `center` : `center`
}))

export const resultsBarHeight = {
  height: `2.5em`
}

const ResultsBar = ({ count }) => {
  return (
    <ResultsBarContainer count={count}>
      {(count === 1) && `${count} RESULT`}
      {(count === 0) && `NO RESULTS`}
      {(count > 1) && `${count} RESULTS`}
    </ResultsBarContainer>
  )
}

export default ResultsBar
