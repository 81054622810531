import React from "react"
import styled from "@emotion/styled"
import { padding, zIndex, mq, colors, typography } from "../../lib/presets"
import Logo from "./logo"
import SearchBar from "./search-bar"
import { withLocator } from "../../providers/locator"

export const navigationBarHeightStyle = {
  height: `55px`
}

const NavigationBarContainer = styled(`nav`)({
  display: `flex`,
  alignItems: `center`,
  width: `100%`,
  boxShadow: `0 0 1.25em 0 rgba(0,0,0,0.04)`,
  zIndex: zIndex.navigationBar,
  overflow: `visible`,
  position: `fixed`,
  background: `#FFFFFF`,
  [mq.desktop]: {
    position: `relative`,
  }
})

const BackButton = styled(`div`)({
  width: `5.0em`,
  height: `4.0em`,
  lineHeight: `4.0em`,
  textAlign: `center`,
  backgroundColor: colors.primary.blue,
  color: colors.turquesa.four,
  paddingTop: `0.5em`,
  paddingBottom: `0.5em`
})

const StoreTitlesContainer = styled(`div`)({
  margin: `-5px 0 0 ${padding.small}`
})

const StoreTitle = styled(`h1`)({
  fontSize: `1.875em`,
  fontFamily: typography.header.family,
  color: colors.turquesa.three,
  margin: 0,
  padding: 0,
  textTransform: `uppercase`
})

const StoreSubtitle = styled(`h2`)({
  fontSize: `0.875em`,
  fontFamily: typography.body.family,
  color: colors.text.light,
  margin: 0,
  padding: 0
})

const NavigationBar = ({locator}) => {
  return (
    <NavigationBarContainer css={[navigationBarHeightStyle]}>
    <Logo /><SearchBar locator={locator} />
    </NavigationBarContainer>
  )
}

/*
const NavigationBar = ({ showBackButton, store, ...props }) => {
  const {locator} = props;
  return (
    <NavigationBarContainer css={[navigationBarHeightStyle]}>
      {showBackButton != true && (<><Logo /><SearchBar locator={locator} /></>)}
      {showBackButton == true && (<>
        <Logo />
        <StoreTitlesContainer>
          <StoreTitle>{store.name}</StoreTitle>
          <StoreSubtitle>{store.address.lineOne}</StoreSubtitle>
        </StoreTitlesContainer>
      </>)}
    </NavigationBarContainer>
  )
}
*/
//export default NavigationBar
export default NavigationBar
