import React from "react"
import { padding, colors, mq, typography } from "../../lib/presets"
import styled from "@emotion/styled"

const InfoContainer = styled(`div`)({
  padding: `${padding.small}`,
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  fontSize: `0.875em`,
  color: colors.text.light,
  fontWeight: 300,
  lineHeight: 1.4,
  [`i`]: {
    float: `left`,
    display: `block`
  },
  [`p`]: {
    marginTop: `-3px`,
    paddingLeft: `4px`
  },

})

const Guarantee = ({ data }) => {
  return (
    <InfoContainer>
      <i className="fas fa-pull-left fa-info-circle"></i>
      <p>Please call to be 100% sure of product offerings at each retailer.</p>
    </InfoContainer>
  )
}

export default Guarantee
